<template>
  <LargeLayout>
    <div class="container">
      <div class="row body">
        <div class="col-12 contact-us">
          <h1
            class="
              contact-us__title
              text-uppercase text-center
              font-weight-bold
              text-success
            "
          >
            {{ $t("contact.Contact Us") }}
          </h1>
          <div class="contact-us__body">
            <p>
              {{ $t("contact.text") }}
            </p>
            <!--<div class="contact-form">
               <b-form @submit.prevent="onSubmit">
                <b-form-group
                  id="input-group-1"
                  :label="$t('contact.email')"
                  label-for="input-1"
                  :description="$t('contact.small text')"
                >
                  <b-form-input
                    id="input-1"
                    type="email"
                    v-model="form.email"
                    :placeholder="$t('contact.enter email')"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-2"
                  :label="$t('contact.name')"
                  label-for="input-2"
                >
                  <b-form-input
                    id="input-2"
                    v-model="form.name"
                    :placeholder="$t('contact.enter name')"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-3"
                  :label="$t('contact.category')"
                  label-for="input-3"
                >
                  <b-form-select
                    id="input-3"
                    v-model="form.category"
                    :options="categories"
                    required
                  ></b-form-select>
                </b-form-group>

                <b-form-group
                  id="input-group-4"
                  :label="$t('contact.title')"
                  label-for="input-4"
                >
                  <b-form-input
                    id="input-4"
                    v-model="form.title"
                    :placeholder="$t('contact.enter title')"
                    required
                  ></b-form-input> </b-form-group
                ><b-form-group
                  id="input-group-5"
                  :label="$t('contact.message')"
                  label-for="input-5"
                >
                  <b-form-textarea
                    id="input-5"
                    v-model="form.message"
                    :placeholder="$t('contact.enter message')"
                    required
                  ></b-form-textarea>
                </b-form-group>
                <div class="text-center">
                  <b-button
                    type="submit"
                    variant="success"
                    class="contact-btn"
                    align-v="center"
                  >
                    <span class="h5">{{ $t("contact.Submit") }}</span></b-button
                  >
                </div>
              </b-form> 
            </div>-->
            <div class="text-center">
              <a
                href="https://line.me/R/ti/p/@738mcjzt"
                target="_blank"
              >
                <b-button
                  type="submit"
                  variant="success"
                  class="contact-btn"
                  align-v="center"
                >
                  <span class="h5">{{ $t("contact.Submit") }}</span></b-button
                >
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </LargeLayout>
</template>

<script>
import LargeLayout from "../../layout/main/LargeLayout.vue";
import axiosConfig from "../../libs/axiosConfig";
export default {
  name: "contact-us",
  components: {
    LargeLayout,
  },
  data() {
    return {
      // form: {
      //   email: "",
      //   name: "",
      //   category: "",
      //   title: "",
      //   message: "",
      // },
      // categories: [
      //   { text: "Select One", value: null },
      //   { text: "Category1", value: "Category1" },
      //   { text: "Category2", value: "Category2" },
      //   { text: "Category3", value: "Category3" },
      //   { text: "Category4", value: "Category4" },
      //   { text: "Other", value: "Other" },
      // ],
    };
  },
  methods: {
    //   onSubmit() {
    //     axiosConfig
    //       .post("/contact", {
    //         email: this.form.email,
    //         name: this.form.name,
    //         category: this.form.category,
    //         title: this.form.title,
    //         message: this.form.message,
    //       })
    //       .then((response) => {
    //         this.form.email = "";
    //         this.form.name = "";
    //         this.form.category = "";
    //         this.form.title = "";
    //         this.form.message = "";
    //         alert(response.data.message);
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   },
  },
};
</script>

<style lang="scss" scoped>
.contact-us {
  .contact-us__title {
    padding: 20px;
  }
  .contact-us__body {
    text-align: center;
    .contact-form {
      // width: 75%;
      margin: auto;
    }
  }
  .contact-btn {
    box-shadow: inset 3px 6px 6px -3px #d2ffe6, inset -3px -7px 6px -3px #00843b;
    padding: 12px 20px !important;
    min-width: 150px;
  }
}
</style>
